<template>
  <article class="stream-preview">
    <aside>
      <v-btn icon @click="close">
        <close-icon />
      </v-btn>
    </aside>

    <main>
      <video :id="videoId" class="video-preview video-js vjs-4-3 vjs-big-play-centered" controls autoplay playsinline></video>
    </main>

<!--    <footer>-->
<!--      <v-row dense no-gutters>-->
<!--        <v-col md="5" sm="7" xs="7">-->
<!--          <div class="stream-title"> {{ stream.title }} </div>-->
<!--          <div class="stream-subtitle"> Preview </div>-->
<!--        </v-col>-->

<!--        <v-col></v-col>-->

<!--        <v-col md="3" sm="6" xs="6">-->
<!--          <v-btn large shaped dark color="#1EAFC1" max-height="48px" max-width="150px" @click="showSettings">Change Receiver</v-btn>-->
<!--        </v-col>-->
<!--        <v-col md="3" sm="6" xs="6">-->
<!--          <v-btn large shaped dark color="#EB5757" max-height="48px" max-width="150px" @click="stopBroadcast">Stop broadcasting</v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </footer>-->
  </article>
</template>

<script>
import registerIVSTech from "@/ivstech"
import videojs from '@/video';

import CloseIcon from './icons/CloseIcon.vue';

let player = null;

export default {
  components: {
    CloseIcon
  },

  props: {
    stream: { type: Object, required: true },
    previewState: { type: Boolean, default: true }
  },

  computed: {
    videoId() {
      return `stream-preview-${this.stream.id}`;
    }
  },

  mounted() {
    setTimeout(() => this.setup(), 1000);
  },

  async beforeDestroy() {
    if(player) {
      player.dispose();
    }
  },

  methods: {
    setup() {
      registerIVSTech(videojs, { })

      player = videojs(`#${this.videoId}`, { techOrder: ["AmazonIVS"] }, () => {
        player.src(this.stream.keys.playbackUrl);
        // player.src('https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8');
        this.$forceUpdate();
      });

    },

    close() {
      this.$emit('close');
    },

    showSettings() {
      this.$emit('show-settings');
    },

    stopBroadcast() {
      this.$emit('stop-broadcast', this.stream.servers, this.stream.arn, this.stream._id);
    }
  },

  watch: {
    previewState(value) {
      if(!value) {
        player.pause();
        //player.off();
      } else {
        player.play();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stream-preview {
  .v-btn {
    text-transform: unset;
  }

  background-color: #FFFFFF;

  width: 100%;
  height: 100%;

  position: relative;

  footer {
    width: 100%;
    padding: 1.5rem;
    padding-right: 1rem;
  }

  aside {
    position: absolute;
    top: 1rem;
    right: 1rem;

    z-index: 20;
  }

  .video-preview {
    width: 100%;
    max-height: 20vh;
  }

  .stream-title {
    font-weight: 500;
    font-size: 24px;

    line-height: 100%;

    color: #185FBF;

    margin-bottom: 0.75rem;
  }

  .stream-subtitle {
    font-weight: normal;
    font-size: 13px;

    line-height: 100%;

    color: #99B4B9;
  }
}
</style>
