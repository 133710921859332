<template>
  <article class="stream">
<!--    <aside class="preview-target" @click="openPreview">-->
    <aside class="preview-target" >
      <div class="thumbnail">
        <video :id="`stream-${stream.id}`" class="video-js vjs-4-3 vjs-big-play-centered" playsinline autoplay muted></video>
      </div>
    </aside>

    <main>
<!--      <section class="preview-target" @click="openPreview">-->
      <section class="preview-target">
        <div class="stream-title">{{ stream.title }}</div>
        <div class="stream-description">
          <template v-if="stream.description">
            {{ stream.description }}
          </template>

          <template v-else>
            Amazon instance is ready <br />
            Arrange input source <br />
            <b>Please delete instance if you're not using it!</b>
          </template>
        </div>
      </section>

      <section v-if="stream.rooms && stream.rooms.length > 0">
        <div class="servers-header">
          Now broadcasting to: {{stream.rooms.length}} screens
        </div>

<!--        <ul>-->
<!--          <li :key="stream.id + server.name" v-for="server in stream.servers">-->
<!--            {{ server.name }}-->
<!--          </li>-->
<!--        </ul>-->
      </section>
    </main>

    <footer>
      <template v-if="stream.status === 'started'">
        <v-btn dark large color="#1EAFC1" @click="changeReceiver(stream)">Change receiver</v-btn>
        <v-btn dark large color="#EB5757" @click="stopBroadcast">Stop broadcasting</v-btn>
      </template>

      <template v-else>
          <v-btn dark large color="#1EAFC1" @click="openStreamForm">Get input data</v-btn>
<!--        <v-dialog v-model="showStreamForm" content-class="dialog-wrapper settings">-->
<!--          <template #activator="{ on }">-->
<!--            <v-btn dark large color="#1EAFC1" v-on="on">Get input data</v-btn>-->
<!--          </template>-->

<!--          <stream-form-->
<!--            :stream="stream"-->
<!--            @delete="deleteStream"-->
<!--            @title-change="changeTitle"-->
<!--            @close="showStreamForm = false"-->
<!--          />-->
<!--        </v-dialog>-->


<!--        <v-btn dark large color="#1EAFC1" @click="changeReceiver(stream)">Change receiver</v-btn>-->

        <v-btn dark large color="#EB5757" @click="deleteStream">
          Delete instance
        </v-btn>
      </template>
    </footer>

    <v-dialog v-model="showPreview" content-class="preview-container">
      <stream-preview
        :stream="stream"
        :previewState="showPreview"
        @show-settings="openSettings"
        @stop-broadcast="stopBroadcast"
        @close="showPreview = false"
      />
    </v-dialog>
  </article>
</template>

<script>
import registerIVSTech from "@/ivstech"
import videojs from '@/video';

// import StreamForm from './StreamForm.vue';
import StreamPreview from './StreamPreview.vue';

import request from '@/plugins/request';

export default {
  components: {
    // StreamForm,
    StreamPreview
  },

  props: {
    stream: { type: Object, required: true },
    isNew: { type: Boolean, default: false }
  },

  data() {
    return {
      // showStreamForm: false,
      showPreview: false,
      showSettings: false,
      // startedInterval: null,
      // createdInterval: null,
      player: null,
      updateInterval: null
    }
  },

  /**
   * Interval for fetching the status
   */

  mounted() {
    this.updateInterval = setInterval(() => {
      this.checkStreamStatus();
    }, 5000);
    this.$nextTick(() => {
      this.checkStreamStatus();
    });

    // this.createdInterval = setInterval(() => {
    //   if (this.stream.status === 'created') {
    //     this.getStatus();
    //   }
    // }, 10e3);
    // this.runCreatedStream();
    //
    // this.runStartedStream();
    // this.$nextTick(() => {
    //   this.getStatus();
    // });
  },

  async beforeDestroy() {
    if(this.player) {
      this.player.dispose();
    }
    clearInterval(this.updateInterval);
  },

  methods: {
    checkStreamStatus() {
      const self = this;
      request({ type: 'GET', url: `/aivs/getStreamByArn?arnKey=${self.stream.arn}` })
        .then(resp => {
          self.$emit('update-status', self.stream.id, resp.data.status);
          switch (resp.data.status) {
            case "started":
              self.$emit('close-form', self.stream);
              if (!self.player) {
                registerIVSTech(videojs, { })
                self.player = videojs(`#stream-${self.stream.id}`, {
                  techOrder: ["AmazonIVS"]
                }, () => {
                  self.player.src(self.stream.keys.playbackUrl);
                });
                self.$forceUpdate();
              }
              break
            case "created":
              if (self.player) {
                self.player = null;
              }
              break
            default:
          }
        })
    },
    changeReceiver(stream) {
      this.$router.push({ path: `/admin/stream/${stream.title}`, query: { arn: stream.arn } });
    },

    // getStatus() {
    //   request({ type: 'GET', url: `/aivs/getStreamByArn?arnKey=${this.stream.arn}` })
    //     .then(res => {
    //       this.$emit('update-status', this.stream.id, res.data.status);
    //
    //       if (this.stream.status === 'started') {
    //         clearInterval(this.createdInterval);
    //
    //         this.runStartedStream();
    //
    //         registerIVSTech(videojs, { })
    //
    //         this.player = videojs(`#stream-${this.stream.id}`, {
    //           techOrder: ["AmazonIVS"]
    //         }, () => {
    //           this.player.src(this.stream.keys.playbackUrl);
    //         });
    //
    //         this.$forceUpdate();
    //       }
    //     })
    // },

    // runCreatedStream() {
    //   this.createdInterval = setInterval(() => {
    //     if (this.stream.status === 'created') {
    //       this.getStatus();
    //     }
    //   }, 10e3);
    // },

    // runStartedStream() {
    //   this.startedInterval = setInterval(() => {
    //     if (this.stream.status === 'started') {
    //       this.$emit('close-form', this.stream);
    //       this.checkStoppedStream();
    //     }
    //   }, 10e3);
    // },

    // checkStoppedStream() {
    //   request({ type: 'GET', url: `/aivs/getStreamByArn?arnKey=${this.stream.arn}` })
    //     .then(res => {
    //       this.$emit('update-status', this.stream.id, res.data.status);
    //
    //       if (this.stream.status === 'created') {
    //         console.log('stopped');
    //         clearInterval(this.startedInterval);
    //
    //         this.runCreatedStream();
    //
    //         // this.stopBroadcast();
    //       }
    //     })
    // },
    openStreamForm() {
      this.$emit('open-stream-form', this.stream);
    },

    closeStreamForm() {
      // this.showStreamForm = false;
    },
    // updateStream() {
    //   this.$emit('update', this.stream)
    //   // this.closeStreamForm();
    // },
    stopBroadcast() {
      this.showPreview = false;
      this.showSettings = false;
      // this.$emit('stop-broadcast', this.stream.rooms, this.stream.arn, this.stream.id);
      this.$emit('stop-broadcast', this.stream);
    },
    deleteStream() {
      this.$emit('delete', this.stream)
      this.closeStreamForm();
    },
    changeTitle(value) {
      this.$emit('title-change', { stream: this.stream.id, ...value });
      // this.closeStreamForm();
    },
    openSettings() {
      this.showPreview = false;
      this.showSettings = true;
    },
    openPreview() {
      if (this.stream.status === 'started') {
        this.showPreview = true;
      }
    }
  },
  // watch: {
  //   stream(value) {
  //     if (value.status === 'started') {
  //       // console.log('Clearing interval: ', this.createdInterval);
  //       clearInterval(this.createdInterval);
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
.preview-container {
  max-width: 45%;
  max-height: 70%;
}

.settings-wrapper {
  max-width: 80%;
}
</style>

<style lang="scss" scoped>
.centered {
  display: grid;
  place-content: center;
}

.preview-target {
  /*cursor: pointer;*/
}

.stream {
  display: grid;

  grid-template-columns: 1fr 2fr 1fr;

  place-content: center;

}

.stream main {
  display: flex;
  flex-flow: row nowrap;

  justify-content: space-between;
  align-items: center;
}

.stream section {
  @extend .centered;

  place-self: center end;

  ul {
    padding: unset;
  }

  li {
    font-size: 13px;
    line-height: 160%;

    color: #252733;

    list-style-type: none;
  }

  .servers-header {
    font-weight: normal;
    font-size: 13px;

    line-height: 100%;

    margin-bottom: 0.5rem;

    color: #99B4B9;
  }
}


.stream .thumbnail {
  border: 1px solid rgba(173, 199, 204, 0.5);
  box-sizing: border-box;
  border-radius: 4px;

  width: 370px;

  transform: scale(0.7);
  // height: 132px;

}

.stream .server-wrapper {
  display: flex;
  flex-flow: column nowrap;

  align-items: flex-end;
}

.stream-title, .servers-header {
  font-weight: 500;
  font-size: 13px;

  line-height: 100%;

  color: #185FBF;
}

.stream-info, .servers, .actions {
  padding: 0;
}


.stream-description {
  max-width: 300px;

  font-weight: normal;
  font-size: 13px;
  line-height: 100%;

  margin-top: 1rem;
}

.stream footer {
  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;

  & > * {
    width: 160px;

    margin: 0.5rem;
  }

  & > .v-btn {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 100%;

    text-transform: unset;

  }
}
</style>
