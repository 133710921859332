<template>
  <article class="stream-field">
    <header>
      {{ label }}
    </header>

    <main>
      <v-text-field disabled solo hide-details :value="link" class="stream-info-fields" />
    </main>

    <aside>
      <v-btn class="ml-4" color="#1EAFC1" @click="copy" width="130px" height="50px">
        <v-icon size="16">far fa-copy</v-icon> Copy
      </v-btn>
    </aside>
  </article>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    link: { type: String, required: true }
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.link);
    }
  }
}
</script>

<style lang="scss" scoped>
.stream-field {
  display: grid;

  grid-template-columns: 1fr auto;
  grid-template-areas:
    "label label"
    "field button";

  place-content: center;
  align-content: center;

  align-items: center;


  padding: 1rem;
  padding-left: 0;

  & > header {
    grid-area: label;
  }

  & > main {
    grid-area: field;
  }

  & > aside {
    grid-area: button;
  }

  .v-input {
    padding: 0;
  }

  .v-btn {
    color: #FFF;
    text-transform: unset;
  }
}

.stream-field > main {
  margin: 0.5rem;
  margin-left: 0;
}

.stream-field > aside {
  .v-icon {
    padding: 0.5rem;
  }
}

.stream-info-fields {
  box-shadow: none;
  border: 1px solid rgba(173, 199, 204, 0.5);
}


</style>
