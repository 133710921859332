<template>
  <v-dialog v-model="showHint" content-class="zoom-wrapper">
    <template #activator="{ on }">
      <v-btn x-small icon depressed v-on="on" width="96" :ripple="false">
        <zoom-icon />
      </v-btn>
    </template>

    <article class="zoom-hint">
      <main class="pt-10">
        <section>
          <div class="image-container">
            <img src="@/assets/stream-hints/zoom-start.png" alt="" />
          </div>

          <div class="hint-title">Choose live on Custom LSS option in your zoom conference</div>
          <div class="hint-subtitle">Available for corporate accouts only</div>
        </section>

        <div>
          <v-divider vertical ></v-divider>
        </div>

        <section>
          <div class="image-container">
            <img src="@/assets/stream-hints/zoom-input.png" alt="" />
          </div>

          <div class="hint-title">Input streaming data and press Go Live</div>
<!--          <div class="hint-subtitle">Short description</div>-->
        </section>

        <div>
          <v-divider vertical ></v-divider>
        </div>

        <section>
          <div class="image-container">
            <img src="@/assets/stream-hints/zoom-finish.png" alt="" />
          </div>

          <div class="hint-title">Wait until everything will set up</div>
<!--          <div class="hint-subtitle">Short description</div>-->
        </section>
      </main>

      <footer>
        <v-btn x-large dark color="#1EAFC1" @click="showHint = false">
          Close hint
        </v-btn>
      </footer>
    </article>
  </v-dialog>
</template>

<script>
import ZoomIcon from '../icons/ZoomIcon.vue';

export default {
  components: {
    ZoomIcon
  },

  data() {
    return {
      showHint: false
    }
  }
}
</script>

<style lang="scss">
.zoom-wrapper {
  max-width: 80%;

  display: flex;

  flex-flow: column;
}
</style>

<style lang="scss" scoped>
.zoom-hint {
  display: flex;
  flex-flow: column nowrap;

  flex-grow: 2;

  align-items: center;

  padding: 2rem;

  background-color: #fff;

  border-radius: 10px;

  height: 100%;

  main {
    display: grid;

    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    grid-template-rows: 100%;

    place-content: center;

    flex-grow: 1;
  }

  section {
    height: 80%;

    display: grid;

    grid-template-rows: 1fr auto 0;

    place-content: center;

    flex-grow: 1;
    padding: 2rem;

    .image-container {
      display: grid;

      place-content: center;
    }

    .hint-title {
      font-weight: 500;
      font-size: 18px;

      line-height: 145%;

      text-align: center;

      color: #185FBF;

      padding: 1rem;
    }

    .hint-subtitle {
      font-size: 13px;

      line-height: 100%;

      text-align: center;

      color: #99B4B9;
    }
  }

  footer {
    .v-btn {
      text-transform: unset;
    }
  }
}

</style>
