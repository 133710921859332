<template>
  <article>
    <div class="stream-form">
      <aside class="close-btn">
        <v-btn icon @click="closeForm">
          <close-icon />
        </v-btn>
      </aside>
      <header>
        <inline-text-field :value="title" @save="onTitleChange"></inline-text-field>
        <span class="info-caption">
          Use this data to set up stream and it will go up automatically
        </span>
        <div>
          <v-btn
            text
            color="red"
            class="ma-0 pa-0 delete-button"
            @click="deleteInstance"
          >
            Delete Instance
          </v-btn>
        </div>
      </header>

      <main>
        <stream-field label="Streaming URL" :link="`rtmps://${stream.keys.ingestEndpoint}:443/app/`" />
        <stream-field label="Streaming Key" :link="stream.keys.streamKey" />
        <stream-field label="Live streaming page URL" :link="stream.keys.playbackUrl" />
      </main>
    </div>

    <footer>
      <v-row>
        <v-col cols="6" class="stream-hint">
          <p class="title">How to input data to start streaming?</p>
          <p class="subtitle">Check out our hints</p>
        </v-col>
        <v-col cols="6" class="services">
          <zoom-hint />
          <youtube-icon />
          <twitch-icon />
        </v-col>
      </v-row>
    </footer>
  </article>
</template>

<script>
import InlineTextField from './InlineTextField.vue';
import StreamField from './StreamField.vue';

import ZoomHint from './hints/ZoomHint.vue';
import YoutubeIcon from './icons/YoutubeIcon.vue';
import TwitchIcon from './icons/TwitchIcon.vue';
import CloseIcon from './icons/CloseIcon.vue';


export default {
  components: {
    InlineTextField,
    StreamField,

    ZoomHint,
    TwitchIcon,
    YoutubeIcon,
    CloseIcon
  },

  props: {
    stream: { type: Object, required: true }
  },

  data() {
    return {
      showZoomHint: false,
      description: this.stream.description
    }
  },

  computed: {
    title: {
      get() {
        return this.stream.title;
      },
      set(value) {
        this.stream.title = value;
      }
    },
    fieldValues() {
      return [
        { value: this.title },
        { value: this.description, style: "font-size: 13px; color: #99B4B9" }
      ]
    }
  },

  methods: {
    deleteInstance() {
      this.$emit('delete', this.stream);
    },

    onTitleChange(value) {
      this.$emit('title-change', { stream: this.stream.id, title: value });
      this.$nextTick(() => {
        this.title = value;
      });
    },
    closeForm() {
      this.$emit('close', this.stream);
    },
  }
}
</script>

<style lang="scss" scoped>
.delete-button {
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  text-transform: none;
}

.stream-form {
  padding: 3.5rem 3.5rem 1rem 3.5rem;
  position: relative;
  background: #FFFFFF;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: stretch;
}

aside {
  position: absolute;
  top: 1rem;
  right: 1rem;

  z-index: 20;
}

.info-caption {
  font-weight: 500;
  font-size: 13px;

  line-height: 100%;

  color: #99B4B9;
}

.stream-info-field {
  min-width: 200px;
}

.stream-hint {
  font-weight: 500;
  font-size: 13px;

  line-height: 100%;

  color: #99B4B9;
}

.stream-hint > .title {
    font-size: 18px;
    line-height: 100%;

    color: #252733;
}

.stream-hint > .subtitle {
    font-size: 13px;
    line-height: 100%;

    color: #99B4B9;
}

.stream-form > section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

footer {
  padding: 3.5rem;

  background-color: #F7F9FB;

  display: flex;
  flex-flow: row nowrap;

  justify-content: space-between;
  align-items: center;

  .v-btn {
    text-transform: unset;
  }
}

.services {
  padding: 1rem;

  display: flex;
  flex-flow: row nowrap;

  justify-content: space-around;
  align-items: center;
}

</style>
