<template>
  <div class="header-nav">
    <v-btn
      text
      link
      to="/admin/users"
      :color="$route.path.includes('users') ? 'blue' : ''"
      >Users</v-btn
    >
        <v-divider class="mx-2" vertical></v-divider>
        <v-btn
          text
          link
          to="/admin/companies"
          :color="$route.path.includes('companies') ? 'blue' : ''"
          >Companies</v-btn
        >
    <v-divider class="mx-2" vertical></v-divider>
    <v-btn
      text
      link
      to="/admin/servers"
      :color="$route.path.includes('servers') ? 'blue' : ''"
      >Servers</v-btn
    >
    <!--    <v-divider class="mx-2" vertical></v-divider>-->
    <!--    <v-btn-->
    <!--      text-->
    <!--      link-->
    <!--      to="/admin/report"-->
    <!--      :color="$route.path !== '/admin/report' ? '' : 'blue'"-->
    <!--      >Report</v-btn-->
    <!--    >-->
    <v-divider class="mx-2" vertical></v-divider>
    <v-btn
      text
      link
      to="/admin/stream"
      :color="$route.path.includes('stream') ? 'blue' : ''"
      >Stream</v-btn
    >
  </div>
</template>

<script>
export default {};
</script>
