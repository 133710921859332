<template>
  <article class="admin-stream admin-page-wrapper">
    <v-dialog v-model="showStreamForm" content-class="dialog-wrapper settings" persistent>
      <stream-form
        :stream="currentStream"
        @delete="deleteStream"
        @title-change="changeTitle"
        @close="closeStreamForm"
      />
    </v-dialog>
    <header>
    <v-row class="admin-header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>

      <v-btn dark large color="#1EAFC1" @click="createStream" :loading="streamCreating">+ Add new stream</v-btn>

      <div class="header-search pl-5">
        <v-text-field
          v-model="search"
          clearable
          color="blur"
          solo
          dense
          hide-details
          clear-icon="mdi-close"
          prepend-inner-icon="mdi-magnify"
          label="Search for value"
          @click:clear="search = null"
        ></v-text-field>
      </div>
    </v-row>
    </header>

    <main>
      <div class="content-wrapper">
        <div class="streams">
          <template v-for="stream in filteredStreams" >
            <stream-entry
              :key="stream.id"
              :stream="stream"
              :isNew="stream.isNew"
              @open-stream-form="openStreamForm"
              @update-status="updateStreamStatus"
              @update="updateStream"
              @stop-broadcast="stopBroadcast"
              @delete="deleteStream"
              @title-change="changeTitle"
              @close-form="closeStreamForm"
            />

            <v-divider :key="'divider-' + stream.id"/>
          </template>

          <v-fade-transition>
            <v-overlay :value="block" absolute>
              <v-progress-circular color="#1EAFC1" indeterminate />
            </v-overlay>
          </v-fade-transition>
        </div>
      </div>
    </main>
  </article>
</template>

<script>
// import registerIVSTech from "@/ivstech"
// import videojs from '@/video';

import AdminTabs from '../AdminTabs.vue';

import StreamEntry from './StreamEntry.vue';

import request from '@/plugins/request';
import StreamForm from "./StreamForm";
import { mapActions } from "vuex";

export default {
  components: {
    AdminTabs,
    StreamEntry,
    StreamForm

  },

  data() {
    return {
      block: false,
      search: '',

      player: null,

      headers: [
        { text: '', value: 'thumbnail', align: 'center' },
        { text: 'Available Streams', value: 'info', align: 'center' },
        { text: 'Updated', value: 'servers', align: 'center' },
        { text: 'Controls', value: 'actions', align: 'center' }
      ],
      streams: [
        // {
        //   id: 'stream-1',
        //   status: 'started',
        //   title: 'Tom Collins Zoom stream',
        //   description: 'Additional info, which we can parse and which can be usefull. F.e. start time, number of participants, time zone etc.',
        //   servers: [
        //     { name: 'RendezVu' },
        //     { name: 'MESAverse'},
        //     { name: 'Convergent'},
        //   ],
        //   keys: {
        //     playbackUrl: 'https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8',
        //     streamKey: '',
        //     ingestEndpoint: ''
        //   }
        // }
      ],
      showStreamForm: false,
      currentStream: {},
      streamCreating: false,
      updateInterval: null,
    }
  },

  computed: {
    filteredStreams() {
      if (this.search) {
        let searchStr = this.search.toLowerCase();
        return this.streams.filter(s => s.title.toLowerCase().includes(searchStr));
      }
      return this.streams;
    },
  },
  async beforeDestroy() {
    if(this.player) {
      this.player.dispose();
    }
    clearInterval(this.updateInterval);
  },

  methods: {
    ...mapActions("ui", ["snackbar"]),
    async createStream() {
      this.streamCreating = true;
      const currentDate = new Date();
      const creationDate = currentDate.toLocaleDateString('en-US');

      request({ method: 'POST', url: '/aivs/create', body: { title: `stream-${this.streams.length + 1}`, description: creationDate} }).then(response => {

        const data = response.data;

        let stream = {
          id: data._id,
          arn: data.arn,
          status: data.status,

          title: data.title,
          description: data.description,

          rooms: [],

          url: data.keys.playbackUrl,
          keys: data.keys,

          isNew: true
        }
        this.streams.unshift(stream);
        this.streamCreating = false;
        this.openStreamForm(stream);
      });
    },

    openStreamForm(stream) {
      this.currentStream = stream;
      this.showStreamForm = true;
    },

    updateStreams() {
      request({ method: 'GET', url: '/aivs/channels'}).then(async response => {
        let streams = await response.data.map(s => {
          return {
            id: s._id,
            arn: s.arn,
            title: s.title,
            description: s.description,
            url: s.keys.playbackUrl,
            status: s.status,
            rooms: s.rooms,
            keys: s.keys
          }
        })
        this.streams = streams;
      });
    },

    getStreams() {
      this.block = true;
      request({ method: 'GET', url: '/aivs/channels'}).then(response => {
        if(response.data == 0) {
          this.block = false;
        }
        
        response.data.forEach(item => {
          this.streams = [
            ...this.streams,
            {
              id: item._id,
              arn: item.arn,
              title: item.title,
              description: item.description,
              url: item.keys.playbackUrl,
              status: item.status,
              rooms: item.rooms,
              keys: item.keys
            }
          ];
          // this.$nextTick(() => {
          //   setTimeout(() => {
          //     registerIVSTech(videojs, { })
          //
          //     this.player = videojs(`#stream-${item._id}`, {
          //       techOrder: ["AmazonIVS"]
          //     }, () => {
          //       this.block = false;
          //
          //       this.player.src(item.keys.playbackUrl);
          //     });
          //   }, 1000)
          // });
        });

        this.block = false;
      });
    },

    deleteStream(stream) {
      this.block = true;
      request({ method: 'POST', url: `/aivs/delete?arnKey=${stream.arn}` })
        .then(()=> {
          this.streams = this.streams.filter(s => s.id !== stream.id);
        }).finally(()=> {
          this.stopBroadcast(stream);
          this.closeStreamForm(stream);
        });

    },
    updateStream(stream) {
      request({ method: 'POST', url: '/aivs/update', body: { arn: stream.arn, title: stream.title } })
        .then(()=> {
          const item = this.streams.find(s => s.id === stream.id);

          item.title = stream.title;
          // item.description = stream.description;
          // item.status = "started";

          this.$forceUpdate();
          // this.streams = this.streams.map(s => s.id === stream.id ? { ...stream, status: 'started' } : s);
        // }).finally(()=> {
          // this.closeStreamForm();
        });
    },

    changeTitle(payload) {
      const updatedStream = { title: payload.title };
      this.streams = this.streams.map(s => s.id === payload.stream ? { ...s, ...updatedStream } : s);
      let updStream = this.streams.find(s => s.id === payload.stream);

      if (updStream) {
        this.updateStream(updStream);
      }
    },
    updateStreamStatus(id, status) {
      const stream = this.streams.find(s => s.id === id);
      stream.status = status;
    },

    closeStreamForm(stream) {
      if (this.showStreamForm && this.currentStream.arn == stream.arn) {
        this.currentStream = {};
        this.showStreamForm = false;
      }
    },

    async stopBroadcast(stream) {
      this.block = true;

      let allPlayers = await request({ method: 'GET', url: '/agora/players' });

      stream.rooms.map(async room => {
        await request({ method: 'POST', url: '/agora/stopPlayer', body: { address: room.serverAddress, roomGuid: room.guid} });

        if (allPlayers.data.some(p => p.name == room.name)) {
          let id = allPlayers.data.find(p => p.name == room.name).id;
          await request({ method: 'DELETE', url: `/agora/delete?id=${id}`});
        }
      });

      if (stream.arn) {
        await request({ method: 'POST', url: `/aivs/stopBroadcasting?arnKey=${stream.arn}` });
      }
      stream.rooms = [];

      if (this.streams.some(s => s.arn == stream.arn)) {
        this.snackbar({
          color: "success",
          icon: "mdi-alert-circle-outline",
          title: `Stopped broadcasting for ${stream.title}`,
          text: "Please delete instance if you're not using it!",
          timeout: -1,
        });
      } else {
        this.snackbar({
          color: "success",
          icon: "mdi-alert-circle-outline",
          title: "Success",
          text: `${stream.title} was deleted`,
          timeout: -1,
        });
      }

      this.block = false;
    },
  },

  mounted() {
    const self = this;
    this.getStreams();
    this.updateInterval = setInterval(self.updateStreams, 5000);
  }
}
</script>

<style lang="scss">
.dialog-wrapper {
  max-width: 80%;
}

</style>


<style lang="scss" scoped>
.admin-stream > main {
  display: flex;

  flex-flow: column nowrap;
  align-items: center;
}

.content-wrapper {
  width: 100%;

  background: #FFFFFF;

  border: 1px solid #E9EEEF;
  border-radius: 4px;

  box-sizing: border-box;
}

.new-stream {

  & > .v-btn {
    font-size: 13px !important;
    line-height: 100%;

    text-transform: unset;

    color: #FFF;
  }
}
</style>
